
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

// FOR ALL
require('./script/main.js');
require('./script/search.js');
//require('./script/ajax.js');
require('./script/price-range-multiple.js');
require('./script/property-page.js');
//require('./script/map-leaflet-property.js');
