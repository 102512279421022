jQuery(document).ready(function($) {


    /*------------------------------------
    * SHOW FOR RENT INPUTS
    ------------------------------------*/
    $('.search-form-o').each(function(){
        var _form = $(this);
        _form.find('select[name="for"]').on('change', function(){
            showRentIputs();
        });
    });

    showRentIputs();

    function showRentIputs(){
        $('.search-form-o').each(function(){
            var _form = $(this);
            var _mode = _form.find('select[name="for"]').val();
            // console.log(_mode);
            if(_mode != 'sale'){
                _form.find('.ul-search').addClass('ul-search-rent');
            }else{
                _form.find('.ul-search').removeClass('ul-search-rent');
            }    
        });
        
    }

    $('.datepicker').datepicker();
    $('.datepicker-o').datepicker({dateFormat: 'yy-mm-dd'});

    /*------------------------------------
    * SORT SEARCH
    ------------------------------------*/

    $('select[name=sort]').on('change', function(){
        var dest = $(this).val();
        if (dest) { window.location.replace(dest); }
    });

    /*------------------------------------
    * "PER PAGE" ON SEARCH PAGE
    ------------------------------------*/

    $('select[name=perpage]').on('change', function(){
        var dest = $(this).val();
        if (dest) { window.location.replace(dest); }
    });

    /*------------------------------------
        DROPDOWN FORMAT
    ------------------------------------*/
    function formatRepo (repo) {

      if (repo.loading) return repo.name;
       var markup = "<div class='select2-result-repository clearfix'>" +
       "<div class='select2-result-repository__meta'>"
       if (repo.name) {
         markup += "<div class='select2-result-repository__description'><strong>" + repo.name+'</strong>';
       }
       markup += "</div></div>";
       return markup;
     }

    function formatRepoSelection (repo) {
      if (repo.name === undefined) {
           return repo.text;
       } else {
           return repo.name;
       }
    }

    /*------------------------------------
        SELECT 2 - AJAX - LOCATIONS
    ------------------------------------*/
    $(".select-pw-ajax-locations").select2({
        multiple: false,
        minimumInputLength: 1,
        tags: false,
        ajax: {
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            url: "/properties/get_locationList",
            dataType: 'json',
            type: 'POST',
            delay: 250,
            data: function (params) {
                var query = {
                  q: params.term,
                  page: params.page || 1,
                  type: 'public'
                }
                return query;
            },
            processResults: function (data, params) {
                //console.log(data.items);
                var query = {
                    results: data.items,
                    pagination: {
                        more: (data.page * 10) < data.total_count
                    }
                }
                return query;
            },
            cache: true,
        },
        escapeMarkup: function (markup) { return markup; }, // let our custom formatter work
        templateResult: formatRepo, // omitted for brevity, see the source of this page
        templateSelection: formatRepoSelection // omitted for brevity, see the source of this page
    });

    // Allow Submit On Search Complete...
    // $(".select-pw-ajax-locations").bind("change keypress", function()
    // {
    //     if (window.event.keyCode === 13)
    //     {
    //         // Allow Submission Of Form...
    //         $('#search-form').submit();
    //     }
    // });
    //
    // $(".select-pw-ajax-locations").on('select2:select', function (e)
    // {
    //     select_change(true);
    // });
    //
    // function select_change(change)
    // {
    //     if(change == true)
    //     {
    //         // Watch For Enter Button Press...
    //         $(document).on('keypress',function(e)
    //         {
    //             if(e.which == 13)
    //             {
    //                 // Submit the Form....
    //                 $('#search-form').submit();
    //             }
    //         });
    //     }
    // }

});
