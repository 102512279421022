$(function(){


  var mapleaf;
  
  function leaflet_map(map_data)
  {
    var map_id = map_data.id;
    var map_zoom = map_data.zoom;
    var map_lat = map_data.lat;
    var map_lng = map_data.lng;
    var map_marker = map_data.marker;
    var themeAsset = $('header').data('themeAsset');

    mapleaf = L.map(map_id).setView([map_lat, map_lng], map_zoom);

    L.tileLayer('https://{s}.tile.osm.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(mapleaf);

    var myIcon = L.divIcon({
      html: '<i class="fas fa-map-marker-alt fx-79 c-primary"></i>',
        iconSize: [59, 79],
        iconAnchor: [29, 79],
        className: 'map--marker-con'

        /*iconUrl: themeAsset+'/images/map/'+map_marker,
        iconSize: [61, 87],
        iconAnchor: [30, 87],
        shadowSize: [0, 0],
        popupAnchor: [0, 0]*/
    });

    L.marker([map_lat, map_lng], {icon: myIcon})
    .addTo(mapleaf);


    /*------------------------------
    * TAB MAP - PROPERTY 2
    *------------------------------*/
    $('#property-tab-1 a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
      var id = $(e.target).attr('id');
      if(id=='map-tab'){
        mapleaf.invalidateSize();
      }
    });

  }

  $('#map-tab').click(function(){

    $('.map-object').each(function() {

      var map_lat = $(this).data('lat');
      var map_lng = $(this).data('lng');
      var map_zoom = $(this).data('zoom');
      var map_marker = $(this).data('marker');
      var map_id = $(this).attr('id');
      map_id = !map_id ? 'property-map' : map_id;
      map_marker = !map_marker ? 'marker.png' : map_marker;

      var map_data = {
        'id' : map_id,
        'zoom' : map_zoom,
        'marker' : map_marker,
        'lat' : map_lat,
        'lng' : map_lng
      };

      leaflet_map(map_data);

    });

  });

    /*------------------------------
    * PROPERTY SLIDER
    *------------------------------*/
    $('.propertySlider-style-1').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        fade: false,
        adaptiveHeight: true,
        asNavFor: '.propertySliderNav-style-1'
    });

    $('.propertySliderNav-style-1').slick({
        slidesToShow: 6,
        slidesToScroll: 1,
        asNavFor: '.propertySlider-style-1',
        dots: false,
        centerMode: false,
        arrows: false,
        focusOnSelect: true,
        responsive: [
           {
             breakpoint: 415,
             settings: {
               slidesToShow: 3
             }
           },
             {
               breakpoint: 601,
               settings: {
                 slidesToShow: 4
             }
           }
         ]
    });

    /*------------------------------
    * STIICKY
    *------------------------------*/
    $("#banner-cta-sticky-1").sticky({topSpacing:0});

    /*------------------------------
    * TAB FLEXY - PROPERTY 1
    *------------------------------*/
    $( window ).resize(function() {
        var pwTabWidth = parseInt(($(".pw-tab-width").outerWidth()));
        tabContentHeight(pwTabWidth);
    });

    var pwTabWidth = parseInt(($(".pw-tab-width").outerWidth()));
    tabContentHeight(pwTabWidth);

    function tabContentHeight(pwTabWidth){
        $('.tab-conten-height').css({'min-height':pwTabWidth+'px'});
    }


    /*------------------------------
    * PROPERTY MORTGAGE
    *------------------------------*/
    function ajaxFormMortgage(id){
        var formObject = $("#ajax-form-"+id);
        formObject.submit(function(){
            var data = $(this).serialize();
            var url = $(this).attr("action");
            var button = $("#btn-"+id).html();
            $("#btn-"+id).html('Loading...');
            $('.mortgage-result-wrap').addClass('d-none');
            $.ajax({
                type: "POST",
                dataType: "json",
                url: url,
                data:data,
                success: function(e){
                  $("#response-"+id).html(e.alert);

                  if(e.output){
                    $("#mortgage_total").html(e.output);
                    $('.mortgage-result-wrap').removeClass('d-none');
                  }

                  $("#btn-"+id).html(button);
                }
            });
            return false;
        });
    }
    /** AJAX FORM **/
    ajaxFormMortgage("mortgage");


});
